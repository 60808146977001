/* You can add global styles to this file, and also import other style files */

@import '~@material/layout-grid/mdc-layout-grid';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
	margin: 0 !important;
}
// Primary
$default-accent-green: #008a5e;
$default-primary-green: #7ac143;

$default-black: #000000;
$default-tiny-black: #333333;
$default-gray: #808080;
$default-white-gray: #ededed;
$default-white: #ffffff;

$default-border-gray: #d8d8d8;
$default-text-black: $default-tiny-black;

$default-background-color: $default-white;

// Feedback
$default-success: #40b530;
$default-warning: #ff9e2d;
$default-danger: #db2c1b;
$default-info: #158fc0;


// html,
// body {
// 	height: 100%;
// }

.cdk-overlay-container {
	z-index: 1010 !important;
}


